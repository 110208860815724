import { Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { CreditTypeRadioCard } from '../components/credit-type-radio-card'

export enum CreditTypeEnum {
  ACCUMULATIVE = 'accumulative',
  TOPUP = 'topup',
}

type CreditTypeProps = {
  selectedValue?: string
  onSelect: (creditType: CreditTypeEnum) => void
}

export const CreditType = ({ selectedValue, onSelect }: CreditTypeProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Typography
        variant="body3"
        variantColor={theme.colors.neutral20}
        weight={700}
      >
        {t('orders.content.modalPreOrder.creditType.content.title')}
      </Typography>

      <Grid.Container
        flexDirection="row"
        gap={theme.spacings.xs}
        marginTop={theme.spacings.xs}
        wrap="nowrap"
      >
        <CreditTypeRadioCard
          description={t(
            'orders.content.modalPreOrder.creditType.content.options.0.description',
          )}
          title={t(
            'orders.content.modalPreOrder.creditType.content.options.0.title',
          )}
          selected={selectedValue === CreditTypeEnum.ACCUMULATIVE}
          onSelect={() => onSelect(CreditTypeEnum.ACCUMULATIVE)}
        />

        <CreditTypeRadioCard
          description={t(
            'orders.content.modalPreOrder.creditType.content.options.1.description',
          )}
          title={t(
            'orders.content.modalPreOrder.creditType.content.options.1.title',
          )}
          selected={selectedValue === CreditTypeEnum.TOPUP}
          onSelect={() => onSelect(CreditTypeEnum.TOPUP)}
        />
      </Grid.Container>
    </Modal.Content>
  )
}
