import { Employee } from '../../../steps/employee-selection/types'

type BenefitMap = {
  [key: string]: {
    emoji: string
    name: string
    amount: number
  }
}

type GroupedBenefit = {
  emoji: string
  name: string
  amount: number
}

export function groupBenefits(employees: Employee[]): GroupedBenefit[] {
  const benefitMap: BenefitMap = {}

  employees.forEach((employee) => {
    employee.benefits.forEach((benefit) => {
      const key = benefit.name

      if (benefitMap[key]) {
        benefitMap[key].amount += benefit.value
      } else {
        benefitMap[key] = {
          emoji: benefit.url,
          name: benefit.name,
          amount: benefit.value,
        }
      }
    })
  })

  return Object.values(benefitMap).sort((a, b) => a.name.localeCompare(b.name))
}
