import { Icons, Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import {
  OrderTotalsContentStyled,
  OrderTotalsHeaderStyled,
  TotalItemContainerStyled,
} from './styles'

type TotalItemProps = {
  total: {
    name: string
    amount: number
  }
}

type OrderTotalsProps = {
  totals: {
    name: string
    amount: number
  }[]
}

export function TotalItem({ total }: TotalItemProps) {
  const theme = useTheme()
  const { name, amount } = total

  return (
    <TotalItemContainerStyled>
      <div>
        <Icons name="IconCreditCard" size={24} />

        <Typography variant="body4" variantColor={theme.colors.neutral30}>
          {name}
        </Typography>
      </div>

      <Typography
        variant="body4"
        variantColor={theme.colors.neutral30}
        weight={700}
      >
        {formatCurrency(amount)}
      </Typography>
    </TotalItemContainerStyled>
  )
}

export function OrderTotals({ totals }: OrderTotalsProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <div>
      <OrderTotalsHeaderStyled>
        <Typography variant="headline8" variantColor={theme.colors.neutral20}>
          {t('newOrder.orderSummary.orderTotals.title')}
        </Typography>

        <Tooltip
          title={t('newOrder.orderSummary.orderTotals.tooltip')}
          arrow
          placement="right"
        >
          <Icons name="IconInfoCircle" size={24} />
        </Tooltip>
      </OrderTotalsHeaderStyled>

      <OrderTotalsContentStyled>
        {totals.map((total, key) => (
          <TotalItem key={key} total={total} />
        ))}
      </OrderTotalsContentStyled>
    </div>
  )
}
