import { useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { useRowAndSubRowSelection } from 'src/utils/hooks'

import { useNewOrderStore } from '../../../use-new-order-store'
import {
  DEBOUNCE_SEARCH_PAGINATION_DELAY,
  DEBOUNCE_SELECTED_DELAY,
} from '../config/constants'
import { Employee, Group } from '../types'
import { getTotalEmployee } from '../utils/get-total-employee'
import { searchEmployeeInGroups } from '../utils/search-employee'
import { useTableConfig } from './use-table-config'

export function useEmployeeSelection() {
  const [searchTerm, setSearchTerm] = useState('')
  const { setSelectedEmployees } = useNewOrderStore()

  const { data = [], isLoading } =
    trpc.simpleAssignment.getEmployees.useQuery(undefined)

  const filteredGroups = searchTerm
    ? searchEmployeeInGroups(data, searchTerm)
    : data

  const {
    areAllSelected,
    selectedSubRows: selectedEmployees,
    toggleSelectAll,
    isHeaderIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    toggleRowSelection,
    toggleSubRowSelection,
  } = useRowAndSubRowSelection<Employee, Group>({
    data: filteredGroups,
    getSubRowId: (subRow) => subRow.id,
  })

  const table = useTableConfig({
    groups: filteredGroups,
    areAllSelected,
    isHeaderIndeterminate,
    isRowIndeterminate,
    isRowSelected,
    selectedEmployees,
    toggleRowSelection,
    toggleSelectAll,
    toggleSubRowSelection,
  })

  const employeeMap = useMemo(() => {
    const map: { [key: string]: Employee } = {}

    data?.forEach((group) => {
      group.subRows.forEach((employee) => {
        map[employee.id] = employee
      })
    })

    return map
  }, [data])

  const memoizedSelectedSubRows = useMemo(
    () => selectedEmployees,
    [selectedEmployees],
  )

  const memoizedTotal = useMemo(
    () => filteredGroups.length,
    [filteredGroups.length],
  )

  useDebounce(
    () => {
      const selectedEmployees = memoizedSelectedSubRows.map(
        (id) => employeeMap[id],
      )

      setSelectedEmployees(selectedEmployees)
    },
    DEBOUNCE_SELECTED_DELAY,
    [memoizedSelectedSubRows],
  )

  useDebounce(
    () => {
      if (searchTerm) {
        table.setPagination((prev) => ({ ...prev, pageNumber: 1 }))
      }
    },
    DEBOUNCE_SEARCH_PAGINATION_DELAY,
    [searchTerm],
  )

  const handleEditGroup = useCallback(() => {
    window.open('/settings/groups', '_blank')
  }, [])

  return {
    tableHeaderGroups: table.getHeaderGroups,
    toggleAllRowsExpanded: table.toggleAllRowsExpanded,
    groups: table.rows,
    total: memoizedTotal,
    totalEmployee: getTotalEmployee(data),
    selectedEmployees,
    areAllSelected,
    isHeaderIndeterminate,
    toggleSelectAll,
    pagination: table.pagination,
    setPagination: table.setPagination,
    setSearchTerm,
    isLoading,
    handleEditGroup,
  }
}
