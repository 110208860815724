import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2'

import { Employee, Group } from '../types'
import { isGroupRow } from '../utils/is-group-row'

type SelectionCheckboxCellProps = {
  groupOrEmployee: Group | Employee
  selectedEmployees: string[]
  isRowSelected: (rowId: string) => boolean
  isRowIndeterminate: (rowId: string) => boolean
  toggleRowSelection: (rowId: string) => void
  toggleEmployeeSelection: (subRowId: string, rowId: string) => void
}

export function SelectionCheckboxCell({
  groupOrEmployee,
  isRowSelected,
  isRowIndeterminate,
  toggleRowSelection,
  selectedEmployees,
  toggleEmployeeSelection,
}: SelectionCheckboxCellProps) {
  if (isGroupRow(groupOrEmployee)) {
    const group = groupOrEmployee as Group

    const isFullySelected = isRowSelected(groupOrEmployee.id)
    const isIndeterminate = isRowIndeterminate(groupOrEmployee.id)

    return (
      <Checkbox
        checked={isFullySelected}
        indeterminate={isIndeterminate}
        onChange={() => toggleRowSelection(groupOrEmployee.id)}
        disabled={group.subRows.length === 0}
      />
    )
  }

  return (
    <Checkbox
      checked={selectedEmployees.includes(groupOrEmployee.id)}
      onChange={() =>
        toggleEmployeeSelection(groupOrEmployee.id, groupOrEmployee.groupId)
      }
    />
  )
}
