import { PillButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

export function ActionCell() {
  const { t } = useTranslation()

  const handleOpenSimpleAssignment = () => {
    window.open('/simple-assignment', '_blank')
  }

  return (
    <Tooltip
      title={t('newOrder.employeeSelection.table.actionCell.tooltip')}
      placement="bottom-end"
    >
      <div>
        <PillButton
          icon="IconExternalLink"
          size="small"
          variant="default"
          onClick={handleOpenSimpleAssignment}
        />
      </div>
    </Tooltip>
  )
}
