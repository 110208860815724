import { useCallback, useMemo, useState } from 'react'

type Row<TSubRow> = {
  id: string
  subRows?: TSubRow[]
}

type UseRowAndSubRowSelectionProps<TSubRow, TRow extends Row<TSubRow>> = {
  data: TRow[]
  getSubRowId: (subRow: TSubRow) => string
}

export function useRowAndSubRowSelection<TSubRow, TRow extends Row<TSubRow>>({
  data,
  getSubRowId,
}: UseRowAndSubRowSelectionProps<TSubRow, TRow>) {
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set())
  const [selectedSubRows, setSelectedSubRows] = useState<Set<string>>(new Set())
  const [selectAll, setSelectAll] = useState(false)

  // Verifica se todos os rows e subRows estão selecionados
  const areAllSelected = useMemo(() => {
    return data.every((row) => {
      if (row.subRows && row.subRows.length > 0) {
        return row.subRows.every((subRow) =>
          selectedSubRows.has(getSubRowId(subRow)),
        )
      }

      return selectedRows.has(row.id)
    })
  }, [data, selectedRows, selectedSubRows, getSubRowId])

  // Verifica se o header está no estado indeterminate
  const isHeaderIndeterminate = useMemo(() => {
    const hasSelectableRows = data.some(
      (row) => (row.subRows && row.subRows?.length) || !row.subRows,
    )

    const hasSelection = selectedRows.size > 0 || selectedSubRows.size > 0

    return hasSelectableRows && hasSelection && !areAllSelected
  }, [areAllSelected, selectedRows, selectedSubRows, data])

  // Verifica se uma row está totalmente selecionada
  const isRowSelected = useCallback(
    (rowId: string) => {
      const row = data.find((row) => row.id === rowId)

      if (row?.subRows && row.subRows.length > 0) {
        return row.subRows.every((subRow) =>
          selectedSubRows.has(getSubRowId(subRow)),
        )
      }

      return selectedRows.has(rowId)
    },
    [data, selectedRows, selectedSubRows, getSubRowId],
  )

  // Verifica se uma row está parcialmente selecionada (indeterminate)
  const isRowIndeterminate = useCallback(
    (rowId: string) => {
      const row = data.find((row) => row.id === rowId)

      if (!row?.subRows || row.subRows.length === 0) {
        return false
      }

      const isSomeSelected = row.subRows.some((subRow) =>
        selectedSubRows.has(getSubRowId(subRow)),
      )

      const isAllSelected = row.subRows.every((subRow) =>
        selectedSubRows.has(getSubRowId(subRow)),
      )

      return isSomeSelected && !isAllSelected
    },
    [data, selectedSubRows, getSubRowId],
  )

  // Alterna a seleção de todas as rows e subRows
  const toggleSelectAll = useCallback(() => {
    if (selectAll || areAllSelected) {
      setSelectedRows(new Set())
      setSelectedSubRows(new Set())
    } else {
      const allRows = new Set(
        data
          .filter((row) => !row.subRows || row.subRows.length > 0)
          .map((row) => row.id),
      )

      const allSubRows = new Set(
        data.flatMap((row) =>
          row.subRows ? row.subRows.map((subRow) => getSubRowId(subRow)) : [],
        ),
      )

      setSelectedRows(allRows)
      setSelectedSubRows(allSubRows)
    }

    setSelectAll((prev) => !prev)
  }, [selectAll, areAllSelected, data, getSubRowId])

  // Alterna a seleção de uma row e suas subRows
  const toggleRowSelection = useCallback(
    (rowId: string) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows)
        const row = data.find((row) => row.id === rowId)

        if (newSelectedRows.has(rowId)) {
          newSelectedRows.delete(rowId)
        } else {
          newSelectedRows.add(rowId)
        }

        if (row?.subRows && row.subRows.length > 0) {
          setSelectedSubRows((prevSelectedSubRows) => {
            const newSelectedSubRows = new Set(prevSelectedSubRows)
            const subRowIds = row.subRows!.map((subRow) => getSubRowId(subRow))
            if (newSelectedRows.has(rowId)) {
              subRowIds.forEach((subRowId) => newSelectedSubRows.add(subRowId))
            } else {
              subRowIds.forEach((subRowId) =>
                newSelectedSubRows.delete(subRowId),
              )
            }
            return newSelectedSubRows
          })
        }

        return newSelectedRows
      })
    },
    [data, getSubRowId],
  )

  // Alterna a seleção de uma subRow
  const toggleSubRowSelection = useCallback(
    (subRowId: string, rowId: string) => {
      setSelectedSubRows((prevSelectedSubRows) => {
        const newSelectedSubRows = new Set(prevSelectedSubRows)
        if (newSelectedSubRows.has(subRowId)) {
          newSelectedSubRows.delete(subRowId)
        } else {
          newSelectedSubRows.add(subRowId)
        }

        const row = data.find((row) => row.id === rowId)
        const allSubRowIds =
          row?.subRows?.map((subRow) => getSubRowId(subRow)) || []

        const isRowFullySelected = allSubRowIds.every((id) =>
          newSelectedSubRows.has(id),
        )

        setSelectedRows((prevSelectedRows) => {
          const newSelectedRows = new Set(prevSelectedRows)
          if (isRowFullySelected) {
            newSelectedRows.add(rowId)
          } else {
            newSelectedRows.delete(rowId)
          }
          return newSelectedRows
        })

        return newSelectedSubRows
      })
    },
    [data, getSubRowId],
  )

  const memorizationSelectedRows = useMemo(
    () => Array.from(selectedRows),
    [selectedRows],
  )

  const memorizationSelectedSubRows = useMemo(
    () => Array.from(selectedSubRows),
    [selectedSubRows],
  )

  return {
    selectedRows: memorizationSelectedRows,
    selectedSubRows: memorizationSelectedSubRows,
    selectAll,
    isHeaderIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    areAllSelected,
    toggleSelectAll,
    toggleRowSelection,
    toggleSubRowSelection,
  }
}
