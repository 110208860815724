import { useMemo } from 'react'

import { useNewOrderStore } from '../../../use-new-order-store'
import { groupBenefits } from '../utils/group-benefits'

export function useOrderSummary() {
  const { selectedEmployees } = useNewOrderStore()

  const hasEmployeeSelected = useMemo(
    () => selectedEmployees.length > 0,
    [selectedEmployees.length],
  )

  const groupedBenefits = useMemo(
    () => groupBenefits(selectedEmployees),
    [selectedEmployees],
  )

  const total = useMemo(
    () => groupedBenefits.reduce((acc, benefit) => acc + benefit.amount, 0),
    [groupedBenefits],
  )

  return {
    hasEmployeeSelected,
    groupedBenefits,
    total,
  }
}
