import { Group } from '../types'

export const searchEmployeeInGroups = (groups: Group[], searchTerm: string) => {
  const regex = new RegExp(searchTerm, 'i')

  return groups.reduce((acc: Group[], group: Group) => {
    const filteredSubRows = group.subRows.filter(
      (subRow) => regex.test(subRow.name) || regex.test(subRow.document),
    )

    if (filteredSubRows.length > 0) {
      acc.push({ ...group, subRows: filteredSubRows })
    }

    return acc
  }, [])
}
