import { Button, Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CreditTypeEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/credit-type'
import { OrderMethodEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/order-method'
import { useToast } from 'src/utils/hooks/useToast'

import { useNewOrderStore } from './use-new-order-store'

export enum Step {
  EMPLOYEE_SELECTION = 0,
  PAYMENT_SETUP = 1,
  REVIEW_DETAILS = 2,
  ORDER_COMPLETED = 3,
}

export function useNewOrderPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [currentStep, setCurrentStep] = useState(Step.EMPLOYEE_SELECTION)
  const { selectedEmployees } = useNewOrderStore()
  const { toastWarning } = useToast()

  const steps = useMemo(
    () => [
      t('newOrder.steps.employeeSelection'),
      t('newOrder.steps.paymentSetup'),
      t('newOrder.steps.reviewDetails'),
      t('newOrder.steps.orderCompleted'),
    ],
    [t],
  )

  const breadcrumb = useMemo(
    () => [
      {
        label: t('newOrder.breadcrumb.orders'),
        route: '/orders',
      },
      {
        label: t('newOrder.breadcrumb.makeOrder'),
      },
    ],
    [t],
  )

  const searchParams = useMemo(() => {
    return {
      creditType: params.get('credit-type'),
      orderMethod: params.get('order-method'),
    }
  }, [params])

  const handleCancel = useCallback(() => {
    navigate('/orders')
  }, [navigate])

  const renderStartActions = useMemo(() => {
    if (currentStep === Step.ORDER_COMPLETED) {
      return null
    }

    return (
      <LinkButton key="cancel-button" variant="neutral" onClick={handleCancel}>
        {t('newOrder.buttons.cancel')}
      </LinkButton>
    )
  }, [currentStep, handleCancel, t])

  const validationNextStep = useCallback(() => {
    if (!selectedEmployees.length) {
      toastWarning({
        title: t('newOrder.warnings.selectEmployee'),
      })

      return false
    }

    return true
  }, [selectedEmployees.length, t, toastWarning])

  const handleNextStep = useCallback(() => {
    if (!validationNextStep()) {
      return
    }

    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.PAYMENT_SETUP,
      [Step.PAYMENT_SETUP]: Step.REVIEW_DETAILS,
      [Step.REVIEW_DETAILS]: Step.ORDER_COMPLETED,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    setCurrentStep((prev) => stepMapping[prev])
  }, [validationNextStep])

  const renderEndActions = useMemo(
    () => (
      <Button
        key="next-button"
        variant="primary"
        size="medium"
        onClick={handleNextStep}
      >
        {t('newOrder.buttons.next')}{' '}
        <Icons name="IconArrowRight" fill="transparent" />
      </Button>
    ),
    [handleNextStep, t],
  )

  useEffect(() => {
    if (
      searchParams.creditType !== CreditTypeEnum.ACCUMULATIVE &&
      searchParams.creditType !== CreditTypeEnum.TOPUP &&
      searchParams.orderMethod !== OrderMethodEnum.PLATFORM
    ) {
      navigate('/orders', { replace: true })
    }
  }, [navigate, searchParams.creditType, searchParams.orderMethod])

  return {
    activeStep: currentStep,
    breadcrumb,
    steps,
    startActions: [renderStartActions],
    endActions: [renderEndActions],
  }
}
