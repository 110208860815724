import { Divider, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { Benefits } from './components/benefits'
import { OrderTotals } from './components/order-totals'
import { TotalPartial } from './components/total-partial'
import { useOrderSummary } from './hooks/use-order-summary'
import { OrderSummaryContainerStyled } from './styles'

export function OrderSummary() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { hasEmployeeSelected, groupedBenefits, total } = useOrderSummary()

  return (
    <OrderSummaryContainerStyled>
      <Grid.Container flexWrap="wrap">
        <Typography variant="headline7" variantColor={theme.colors.neutral20}>
          {t('newOrder.orderSummary.title')}
        </Typography>
      </Grid.Container>

      <Divider orientation="horizontal" />

      {hasEmployeeSelected ? (
        <>
          <Benefits
            title={t('newOrder.orderSummary.benefitsTitle')}
            benefits={groupedBenefits}
          />

          <Divider orientation="horizontal" />

          <OrderTotals
            totals={[
              {
                name: t('newOrder.orderSummary.orderTotals.item.benefitsCard'),
                amount: total,
              },
            ]}
          />

          <Divider orientation="horizontal" />

          <TotalPartial amount={total} />
        </>
      ) : (
        <Grid.Container flexWrap="wrap">
          <Typography variant="body3" variantColor={theme.colors.neutral30}>
            {t('newOrder.orderSummary.noSelection')}
          </Typography>
        </Grid.Container>
      )}
    </OrderSummaryContainerStyled>
  )
}
