import { create } from 'zustand'

import { Employee } from './steps/employee-selection/types'

type NewOrderStoreState = {
  selectedEmployees: Employee[]
}

type NewOrderStoreSetters = {
  setSelectedEmployees: (employees: Employee[]) => void
}

type NewOrderStore = NewOrderStoreState & NewOrderStoreSetters

const initialState: NewOrderStoreState = {
  selectedEmployees: [],
}

export const useNewOrderStore = create<NewOrderStore>((set) => ({
  ...initialState,
  setSelectedEmployees: (employees) => {
    set({ selectedEmployees: employees })
  },
}))
