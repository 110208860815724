import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import { TotalPartialContainerStyled } from './styles'

type TotalPartialProps = {
  amount: number
}

export function TotalPartial({ amount }: TotalPartialProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <TotalPartialContainerStyled>
      <Typography variant="headline8" variantColor={theme.colors.neutral30}>
        {t('newOrder.orderSummary.orderTotals.totalPartial.title')}
      </Typography>

      <Typography variant="headline6" variantColor={theme.colors.secondary50}>
        {formatCurrency(amount)}
      </Typography>
    </TotalPartialContainerStyled>
  )
}
