import { Checkbox, tableControllers } from '@flash-tecnologia/hros-web-ui-v2'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { usePagination } from 'src/utils/hooks'
import { formatCurrency } from 'src/utils/mask'

import { ActionCell } from '../components/ActionCell'
import { EmojisCell } from '../components/EmojisCell'
import { GroupNameCell } from '../components/GroupNameCell'
import { SelectionCheckboxCell } from '../components/SelectionCheckboxCell'
import { COLUMN_SIZES } from '../config/constants'
import { Group } from '../types'
import { isEmployeeRow } from '../utils/is-employee-row'

type UseTableConfigProps = {
  groups: Group[]
  areAllSelected: boolean
  isHeaderIndeterminate: boolean
  toggleSelectAll: () => void
  selectedEmployees: string[]
  isRowSelected: (id: string) => boolean
  isRowIndeterminate: (id: string) => boolean
  toggleRowSelection: (id: string) => void
  toggleSubRowSelection: (id: string, groupId: string) => void
}

export function useTableConfig({
  groups,
  areAllSelected,
  isHeaderIndeterminate,
  toggleSelectAll,
  selectedEmployees,
  isRowSelected,
  isRowIndeterminate,
  toggleRowSelection,
  toggleSubRowSelection,
}: UseTableConfigProps) {
  const { t } = useTranslation()
  const { pagination, setPagination, getPaginatedData } = usePagination()

  const data = getPaginatedData(groups)

  const columnHelper = createColumnHelper<Group>()

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'checkbox',
        size: COLUMN_SIZES.checkbox,
        header: () => (
          <Checkbox
            checked={areAllSelected}
            indeterminate={isHeaderIndeterminate}
            onChange={toggleSelectAll}
          />
        ),
        cell: ({ row }) => (
          <SelectionCheckboxCell
            groupOrEmployee={row.original}
            selectedEmployees={selectedEmployees}
            isRowSelected={isRowSelected}
            isRowIndeterminate={isRowIndeterminate}
            toggleRowSelection={toggleRowSelection}
            toggleEmployeeSelection={toggleSubRowSelection}
          />
        ),
      }),
      columnHelper.accessor('name', {
        header: t('newOrder.employeeSelection.table.columns.group'),
        size: COLUMN_SIZES.name,
        cell: ({ row }) => <GroupNameCell groupOrEmployee={row.original} />,
      }),
      columnHelper.accessor('benefits', {
        header: t('newOrder.employeeSelection.table.columns.benefits'),
        size: COLUMN_SIZES.benefits,
        cell: ({ row }) => <EmojisCell groupOrEmployee={row.original} />,
      }),
      columnHelper.accessor('total', {
        header: t('newOrder.employeeSelection.table.columns.totalAmount'),
        size: COLUMN_SIZES.amount,
        cell: ({ row }) => formatCurrency(row.getValue('total')),
      }),
      columnHelper.display({
        id: 'action',
        header: '',
        size: COLUMN_SIZES.action,
        cell: ({ row }) => isEmployeeRow(row.original) && <ActionCell />,
      }),
    ],
    [
      areAllSelected,
      columnHelper,
      isHeaderIndeterminate,
      isRowIndeterminate,
      isRowSelected,
      selectedEmployees,
      t,
      toggleRowSelection,
      toggleSelectAll,
      toggleSubRowSelection,
    ],
  )

  const { getHeaderGroups, toggleAllRowsExpanded, rows } =
    tableControllers.useTableColumns({
      options: { expandable: true },
      columns: columns as never,
      pagination,
      data,
      onPaginationChange: setPagination,
    })

  return {
    getHeaderGroups,
    toggleAllRowsExpanded,
    rows,
    pagination,
    setPagination,
  }
}
