import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Emoji } from 'src/components/emoji'
import { formatCurrency } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import {
  BenefitItemWrapperStyled,
  BenefitsContainerStyled,
  BenefitsListWrapperStyled,
} from './styles'

type Benefit = {
  name: string
  emoji: string
  amount: number
}

type BenefitsProps = {
  title: string
  benefits: Benefit[]
}

type BenefitItemProps = {
  benefit: Benefit
}

function BenefitItem({ benefit }: BenefitItemProps) {
  const theme = useTheme()
  const { name, emoji, amount } = benefit

  return (
    <BenefitItemWrapperStyled>
      <Tooltip title={name} arrow placement="left">
        <div>
          <Emoji imgSrc={emoji} />

          <Typography variant="body4" variantColor={theme.colors.neutral30}>
            {name}
          </Typography>
        </div>
      </Tooltip>

      <Typography
        variant="body4"
        variantColor={theme.colors.neutral30}
        weight={700}
      >
        {formatCurrency(amount)}
      </Typography>
    </BenefitItemWrapperStyled>
  )
}

export function Benefits({ title, benefits }: BenefitsProps) {
  const theme = useTheme()

  return (
    <BenefitsContainerStyled>
      <Typography
        variant="headline8"
        variantColor={theme.colors.neutral20}
        weight={700}
      >
        {title}
      </Typography>

      <BenefitsListWrapperStyled>
        {benefits.map((benefit, key) => (
          <BenefitItem key={key} benefit={benefit} />
        ))}
      </BenefitsListWrapperStyled>
    </BenefitsContainerStyled>
  )
}
