import { Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { OrderMethodRadioCard } from '../components/order-method-radio-card'

export enum OrderMethodEnum {
  PLATFORM = 'platform',
  SPREADSHEET = 'spreadsheet',
}

type OrderMethodProps = {
  selectedValue?: string
  onSelect: (orderMethod: OrderMethodEnum) => void
}

export const OrderMethod = ({ selectedValue, onSelect }: OrderMethodProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Modal.Content>
      <Grid.Container flexDirection={'column'} gap={theme.spacings.xs}>
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral20}
          weight={700}
        >
          {t('orders.content.modalPreOrder.orderMethod.content.title')}
        </Typography>

        <OrderMethodRadioCard
          description={t(
            'orders.content.modalPreOrder.orderMethod.content.options.0.description',
          )}
          title={t(
            'orders.content.modalPreOrder.orderMethod.content.options.0.title',
          )}
          icon="IconUsers"
          selected={selectedValue === OrderMethodEnum.PLATFORM}
          onSelect={() => onSelect(OrderMethodEnum.PLATFORM)}
        />

        <OrderMethodRadioCard
          description={t(
            'orders.content.modalPreOrder.orderMethod.content.options.1.description',
          )}
          title={t(
            'orders.content.modalPreOrder.orderMethod.content.options.1.title',
          )}
          icon="IconTable"
          selected={selectedValue === OrderMethodEnum.SPREADSHEET}
          onSelect={() => onSelect(OrderMethodEnum.SPREADSHEET)}
        />
      </Grid.Container>
    </Modal.Content>
  )
}
