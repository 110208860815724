import { ContainerStyled } from './styles'

export interface IEmojiListItemProps {
  imgSrc: string
  width?: number
}

export const Emoji: React.FC<IEmojiListItemProps> = ({
  imgSrc,
  width = 32,
}: IEmojiListItemProps) => {
  return (
    <ContainerStyled $width={width}>
      <img src={imgSrc} alt="emoji" />
    </ContainerStyled>
  )
}
