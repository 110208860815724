import styled from 'styled-components'

export const EmployeeSelectionContainerStyled = styled.div`
  height: max-content;

  border-radius: ${({ theme }) => theme.borders.radius.m};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral90};
  border-style: solid;

  padding: ${({ theme }) => `${theme.spacings.s} ${theme.spacings.xs}`};
`

export const HeaderWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs5};
`

export const SearchWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  justify-items: end;

  > div button {
    border-radius: ${({ theme }) =>
      theme.borders.radius
        .xl2} !important; // Adicionado para deixar como proposto no figma
  }
`

export const GroupNameWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;

    column-gap: ${({ theme }) => theme.spacings.xs5};

    color: ${({ theme }) => theme.colors.neutral60};
  }
`
