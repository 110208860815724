import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Focused } from 'src/components/focused'
import { useTheme } from 'styled-components'

import { OrderSummary } from './components/order-summary'
import { EmployeeSelection } from './steps/employee-selection'
import { ContentContainerStyled } from './styles'
import { Step, useNewOrderPage } from './use-new-order-page'

export default function NewOrderPage() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { activeStep, breadcrumb, steps, startActions, endActions } =
    useNewOrderPage()

  return (
    <Focused
      activeStep={activeStep}
      breadcrumb={breadcrumb}
      steps={steps}
      footer={{
        startActions,
        endActions,
      }}
    >
      <Typography
        variant="headline6"
        weight={700}
        variantColor={theme.colors.neutral20}
      >
        {t('newOrder.title')}
      </Typography>

      <ContentContainerStyled>
        {activeStep === Step.EMPLOYEE_SELECTION && (
          <>
            <EmployeeSelection />

            <OrderSummary />
          </>
        )}
      </ContentContainerStyled>
    </Focused>
  )
}
