import { OrderStatusEnum as OrderStatusTagEnum } from 'src/components/order-confirmation-card/components/header/components/tags/order-status-tag'
import { DepositStatusEnum } from 'src/enums/depositStatusEnum'
import { OrderStatusEnum } from 'src/enums/orderStatusEnum'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { LocaleKeys } from './localeKeys'

const translations: LocaleKeys = {
  translations: {
    errorBoundary: {
      title: 'Um erro inesperado aconteceu',
      description:
        'Não conseguimos encontrar a página que você está procurando. Pedimos desculpas pelo ocorrido.',
      button: 'Voltar para a página inicial',
    },
    optionalTag: 'Opcional',
    modalAlertConfirm: {
      attention: 'Atenção',
      btnCancel: 'Cancelar',
      btnConfirm: 'Confirmar',
    },
    modalCancel: {
      attention: 'Atenção',
      btnBack: 'Fechar',
      btnCancel: 'Sim, cancelar',
      order: {
        message:
          'Tem certeza que deseja cancelar o pedido #{{orderId}} no valor de {{amount, currency(BRL)}}?',
        alertMessage:
          'Essa ação não poderá ser desfeita. Pedidos com o status de "disponibilização agendada" terão os valores estornados para o Flash Cash da empresa.',
      },
      deposit: {
        message: 'Tem certeza que deseja cancelar o depósito de {{name}}?',
        alertMessage:
          'Após o depósito ser cancelado a pessoa não receberá mais o crédito solicitado. Depósitos com o status de "disponibilização agendada" terão seus valores convertidos em saldo de Flash Cash benefícios.',
      },
    },
    modalWarn: {
      attention: 'Atenção',
      btnConfirm: 'Ok, entendi',
    },
    modalEmployeesList: {
      title: 'Pessoas do pedido',
      description: 'Veja as pessoas selecionadas para o pedido em andamento',
      tableName: 'Pessoas do pedido',
      searchPlaceholder: 'Busque pelo nome ou CPF',
      tableColumns: {
        people: 'Pessoas',
        company: 'Empresa',
        amount: 'Valor total',
        unitValue: '{{value, currency(BRL)}}',
      },
    },
    order: {
      errors: {
        generic: 'Erro ao gerar pedido',
        reportError: 'Erro ao gerar relatório',
        emptySelection:
          'Por favor, selecione novamente os colaboradores do pedido e tente novamente',
        emptyPaymentMethod: 'Selecione uma forma de pagamento',
        emptyDepositDate: 'Selecione a data de depósito',
        balance: 'Saldo insuficiente',
        balanceDescription:
          'Não há saldo suficiente em Flash Cash para realizar o pedido de benefício. Realize uma recarga ou altere o método de pagamento para continuar.',
        token: 'Token inválido',
        flashCash: {
          getBalanceTitle: 'Erro ao buscar saldo de flash cash',
          getBalanceDescription:
            'Parece que houve um erro ao buscar o saldo de Flash Cash. Por favor, tente novamente em alguns instantes.',
        },
      },
      pageTitle: 'Pedido de benefício pela plataforma',
      paymentMethods: {
        header: {
          title: 'Escolha o método de pagamento e a data de disponibilização',
          titleTopup: 'Benefícios com recarga inteligente',
          // subtitle:
          //   'Selecione a forma de pagamento entre Boleto ou Flash Cash.',
          subtitle: 'Selecione a forma de pagamento.',
        },
        title: 'Método de pagamento',
        [PaymentMethodEnum.BILLET]: 'Boleto',
        [PaymentMethodEnum.PIX]: 'Pix',
        [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
        topupWarning: {
          title: 'Importante',
          subtitle:
            'Pedidos de benefícios com recarga inteligente só podem ser pagos com Flash Cash.',
        },
      },
      checkoutDates: {
        availabilityDate: {
          title: 'Data de disponibilização dos benefícios',
          subtitle:
            'Determine a data que seus colaboradores receberão os benefícios.',
          depositDateInput: {
            label: 'Escolha a data de disponibilização dos benefícios',
            inputPlaceholder: 'Data de disponibilização',
            format: 'DD/MM/AAAA',
          },
          pixDueDateInput: {
            label: 'Dia de vencimento do código Pix',
            inputPlaceholder: 'Vencimento do código Pix',
          },
          billetDueDateInput: {
            label: 'Dia de vencimento do boleto',
            inputPlaceholder: 'Vencimento do boleto',
          },
        },
        cutoffDate: {
          title: 'Data e hora de corte para a definição do valor de recarga',
          subtitle:
            'Nesse dia e hora, o sistema irá ler o valor de saldo do colaborador e calcular qual deve ser o valor da recarga. Exemplo: se o saldo da pessoa é de 180 reais mensais e até a data e hora escolhida ela utilizou somente 160 reais, apenas estes 160 reais serão gastos no pedido, complementando o saldo.',
          cutoffDateInput: {
            inputPlaceholder: 'Escolha a data de corte',
            format: 'DD/MM/AAAA',
          },
          cutoffTimeInput: {
            inputPlaceholder: 'Escolha a hora do corte',
          },
        },
      },
      receiptDescription: {
        label: 'Descrição adicional na nota fiscal',
        helperText: 'Máximo {{max}} caracteres',
        placeholder: 'Adicione uma descrição adicional a nota fiscal do pedido',
      },
      balances: {
        title: 'Saldo na carteira de Flash Cash:',
        plastic: 'Benefícios no cartão',
        virtual: 'Benefícios virtuais',
        value: '{{value, currency(BRL)}}',
      },
      checkout: {
        orderSummary: {
          title: 'Resumo do pedido',
          employeesCount: 'Pedido para {{employeesCount}} pessoas',
          orderEmployeesButton: 'Pessoas do pedido',
          fees: {
            value: '{{value, currency(BRL)}}',
            title: 'Taxas',
            accountsFee: 'Manutenção de contas',
          },
          discounts: {
            value: '{{value, currency(BRL)}}',
            title: 'Descontos',
            reimbursementsFee: 'Reembolso de taxas',
          },
          benefits: {
            value: '{{value, currency(BRL)}}',
            title: 'Benefícios Flash',
          },
          topup: {
            value: '{{value, currency(BRL)}}',
            title: 'Benefícios com recarga inteligente',
          },
          totalAmount: {
            value: '{{value, currency(BRL)}}',
            title: 'Total dos itens do pedido',
            plastic: 'Benefícios no cartão',
            virtual: 'Benefícios virtuais',
            tooltip:
              'O total dos itens do pedido são discriminados conforme a forma de utilização dos benefícios.',
          },
          total: 'Total Parcial',
          value: 'Valor Parcial',
        },
      },
      statusTag: {
        [OrderStatusTagEnum.PAID]: 'Pago',
        [OrderStatusTagEnum.PENDING]: 'Aguardando pagamento',
      },
      confirmation: {
        sideGrid: {
          [PaymentMethodEnum.BILLET]: {
            title: 'Pedido realizado',
            subtitle:
              'Seu pedido foi feito e será processado após o pagamento do boleto',
          },
          [PaymentMethodEnum.PIX]: {
            title: 'Pedido realizado',
            subtitle:
              'Realize a transferência por pix para processarmos seu pedido',
          },
          [PaymentMethodEnum.FLASH_CASH]: {
            title: 'Pedido concluído',
            subtitle:
              'Seu pedido foi processado com seus créditos em Flash Cash',
          },
        },
        cardGrid: {
          title: 'Pedido #{{code}}',
          subtitle: {
            [PaymentMethodEnum.BILLET]: 'O boleto será enviado para o e-mail',
            [PaymentMethodEnum.PIX]:
              'O código Pix também será enviado para o e-mail',
            [PaymentMethodEnum.FLASH_CASH]:
              'Transação com Flash Cash concluída com sucesso.',
          },
          infos: {
            amount: 'Valor do pedido',
            dueDate: {
              [PaymentMethodEnum.BILLET]: 'Vencimento do boleto',
              [PaymentMethodEnum.PIX]: 'Vencimento do código Pix',
            },
            depositDate: 'Disponibilização',
          },
          buttons: {
            links: {
              deposits: 'Ir para depósitos',
              [PaymentMethodEnum.BILLET]: 'Ir para boletos',
              [PaymentMethodEnum.PIX]: 'Ir para área Pix',
            },
            generateReport: 'Gerar relatório do pedido',
          },
          reminder: {
            title: 'Lembre-se',
            subtitle: {
              prefix: 'É possível cancelar depósitos deste pedido até o dia',
              sufix: {
                [PaymentMethodEnum.BILLET]: 'através da página de Boletos',
                [PaymentMethodEnum.PIX]: 'através da área Pix',
                [PaymentMethodEnum.FLASH_CASH]:
                  'através da página de Flash Cash',
              },
            },
          },
        },
        confirmCodeSubtitle:
          'Para realizar seu pedido utilizando o saldo de Flash Cash de benefícios, é necessário inserir o token enviado para o seu e-mail',
        pixInfo: {
          title: 'Pagar com Pix',
          description:
            'Pague em qualquer dia e horário até o vencimento. Pedidos realizados para o mesmo dia serão depositados em até duas horas após o pagamento',
          subtitle: 'Você também pode pagar usando o código “Pix Copia e cola”',
          copyButton: 'Copiar código Pix',
          stepsTitle: 'Passo-a-passo para o pagamento via QR- CODE',
          steps: [
            'No app do banco ou instituição financeira entre na área Pix',
            'Escolha a opção pagar com QR Code e escaneie o código ao lado',
            'Confirme as informações e finalize o pagamento',
          ],
        },
      },
      review: {
        title: 'Método de pagamento',
        sideGrid: {
          title: 'Revise os detalhes do pedido',
          subtitle:
            'Aproveite pra conferir os valores e benefícios que fazem parte do seu pedido.',
        },
        cardGrid: {
          title: 'Resumo do pedido',
          titleTopup: 'Resumo do pedido de benefícios com recarga inteligente',
          orderSummary: {
            title: 'Itens do pedido',
            depositDate: {
              title: 'Disponibilização dos benefícios',
            },
            employeesCount: 'Pessoas contempladas',
          },
          paymentMethod: {
            [PaymentMethodEnum.BILLET]: {
              name: 'Boleto bancário',
              description:
                'O pagamento pode ser realizado através de uma conta ou agência bancária e tem o prazo de compensação de até 3 dias úteis.',
            },
            [PaymentMethodEnum.PIX]: {
              name: 'Pix',
              description:
                'Pague com Pix em qualquer dia e a qualquer hora, o pagamento é instantâneo, prático e pode ser feito em poucos segundos. É rápido e seguro.',
            },
            [PaymentMethodEnum.FLASH_CASH]: {
              name: 'Flash Cash benefícios',
              description:
                'Faça pedidos em tempo real para as pessoas da empresa. Tenha dinheiro na conta de Flash Cash para fazer depósitos sempre que quiser.',
            },
          },
          totalAmount: {
            title: 'Total do pedido',
            titleTopup: 'Valor máximo do pedido',
            value: '{{value, currency(BRL)}}',
          },
          infoTopup: {
            title: 'Valor máximo do pedido',
            description:
              'Este é um valor estimado que considera o máximo que este pedido poderá custar. Apenas após a data de corte teremos o valor real a ser pago considerando o quanto foi economizado com a recarga inteligente.',
          },
        },
        loading: {
          title: 'Fechando os itens do seu pedido na Flash',
          subtitle: 'Por favor, aguarde',
        },
      },
      flashCashTopUpModal: {
        btnCancel: 'Agora não',
        btnConfirm: 'Fazer recarga',
        title: 'Saldo de Flash Cash insuficiente!',
        message:
          'Recarregue o seu saldo de Flash Cash agora para poder garantir a efetivação do pedido realizado.',
        warning:
          'Caso a recarga não seja realizada antes do dia {{cutOffDate}}, o pedido do benefício com pagamento em Flash Cash não será realizado.',
      },
    },
    orders: {
      headerPage: {
        title: 'Pedidos realizados',
        subtitle:
          'Acompanhe os pedidos de benefícios da empresa e seus status de disponibilização',
      },
      content: {
        headerContent: {
          title: 'Pedidos de benefícios da empresa',
          textTag: 'itens',
        },
        emptyState: {
          title: 'Você ainda não fez pedidos de benefício',
          subtitle: 'Novos pedidos da empresa aparecerão aqui',
          buttonLink: 'Conheça a página de pedidos',
        },
        table: {
          columns: {
            code: 'Código',
            requested: 'Solicitado em',
            paymentMethod: 'Pagamento',
            value: 'Valor',
            creditDate: 'Disponibilização',
            status: 'Status',
          },
          cells: {
            paymentMethod: {
              [PaymentMethodEnum.BILLET]: 'Boleto',
              [PaymentMethodEnum.PIX]: 'Pix',
              [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
            },
            value: '{{value, currency(BRL)}}',
            status: {
              [OrderStatusEnum.AVAILABLE]: 'Disponibilizado',
              [OrderStatusEnum.BILLED]: 'Aguardando pagamento',
              [OrderStatusEnum.CANCELED]: 'Cancelado',
              [OrderStatusEnum.CONFIRMED]: 'Confirmado',
              [OrderStatusEnum.PAID]: 'Disponibilização agendada',
              [OrderStatusEnum.REFUNDED]: 'Estornado',
              [OrderStatusEnum.REQUESTED]: 'Solicitado',
            },
          },
          pagination: {
            selectItems: '{{value}} itens',
          },
          actions: {
            details: 'Detalhes do pedido',
            cancel: 'Cancelar pedido',
            downloadBillet: {
              [PaymentMethodEnum.BILLET]: 'Baixar boleto',
              [PaymentMethodEnum.PIX]: 'Baixar código pix',
            },
            downloadDebitNote: 'Baixar nota de débito',
            downloadInvoice: 'Baixar nota fiscal',
          },
          errors: {
            cancelError: 'Erro ao cancelar pedido',
          },
          success: {
            cancelOrder: 'Pedido cancelado com sucesso',
          },
        },
        modalPreOrder: {
          creditType: {
            title: 'Qual o tipo de benefício do pedido?',
            description:
              'Você possui pessoas que estão atribuídas à dois tipos de benefícios',
            content: {
              title:
                'Escolha para qual tipo de benefício você gostaria de fazer o pedido e prossiga.',
              options: [
                {
                  title: 'Benefício padrão',
                  description:
                    'As pessoas recebem o valor total definido para o benefício, e ele se acumula com o saldo restante depositado no mês passado. Pode ser pago com boleto, pix ou Flash Cash',
                },
                {
                  title: 'Recarga inteligente',
                  description:
                    'As pessoas recebem um depósito que respeita o limite de saldo definido para o benefício, e você define a data e horário para a renovação do saldo. Pode ser pago somente com Flash Cash',
                },
              ],
            },
          },
          orderMethod: {
            title: 'Como quer fazer seu pedido de benefício?',
            description:
              'Pedidos de benefício podem ser feitos de maneira individual, por grupo ou planilha.',
            content: {
              title: 'Escolha o método de pedido dos benefícios',
              options: [
                {
                  title: 'Pedido por seleção na plataforma',
                  description:
                    'Faça o pedido selecionando pessoas ou grupos previamente cadastrados na Flash',
                },
                {
                  title: 'Pedido por planilha',
                  description:
                    'Ideal para pedidos com grande número de pessoas, aproveite para cadastrar e atribuir valores aos benefícios no processo.',
                },
              ],
            },
          },
          steps: ['Tipo de benefício', 'Método de pedido'],
          continueButton: 'Continuar',
          backButton: 'Voltar',
          cancelButton: 'Cancelar',
        },
      },
      buttonText: 'Fazer novo pedido',
      failedToFetchMessage: 'Erro ao buscar informações de pedidos realizados',
    },
    orderDetails: {
      headerPage: {
        title: 'Detalhes do pedido de benefício',
        subtitle: 'Depósitos',
      },
      breadcrumbLabel: 'Detalhes do pedido #{{code}}',
      content: {
        headerContent: {
          title: 'Depósitos',
          textTag: 'itens',
        },
        summary: {
          title: 'Pedido #{{code}}',
          created: 'Realizado em',
          creditDate: 'Disponibilização dos créditos',
          paymentMethod: 'Método de pagamento',
          paymentMethodTypes: {
            [PaymentMethodEnum.BILLET]:
              'Boleto bancário (Compensação em até 3 dias úteis)',
            [PaymentMethodEnum.PIX]: 'Pix',
            [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
          },
          total: 'Total do pedido',
          amount: {
            value: '{{value, currency(BRL)}}',
          },
          fees: 'Taxas',
          description: 'Descrição adicional na nota fiscal',
        },
        emptyState: {
          title: 'Desculpe, não encontramos sua busca',
          subtitle:
            'Confira se as informações estão corretas e tente novamente',
        },
        table: {
          columns: {
            employeeDocument: 'CPF',
            employeeName: 'Pessoa',
            benefitName: 'Benefício da pessoa',
            value: 'Valor',
            status: 'Status',
            creditDate: 'Disponibilização',
          },
          cells: {
            status: {
              [DepositStatusEnum.SCHEDULED]: {
                title: 'Disponibilização programada',
                style: 'blue',
              },
              [DepositStatusEnum.TRANSFERRED]: {
                title: 'Disponibilizado',
                style: 'green',
              },
              [DepositStatusEnum.CANCELED]: {
                title: 'Cancelado',
                style: 'yellow',
              },
              [DepositStatusEnum.REFUNDED]: {
                title: 'Estornado',
                style: 'pink',
              },
              [DepositStatusEnum.CREATED]: {
                title: 'Aguardando pagamento',
                style: 'gray',
              },
              [DepositStatusEnum.WAITING_TO_BE_CALCULATED]: {
                title: 'Agendado (recarga inteligente)',
                style: 'gray',
              },
            },
            value: '{{value, currency(BRL)}}',
          },
          pagination: {
            selectItems: '{{value}} itens',
          },
          actions: {
            refund: 'Estorno de benefício',
            cancel: 'Cancelar depósito da pessoa',
          },
          errors: {
            cancelError: 'Erro ao cancelar depósito',
          },
          success: {
            cancelDeposit: 'Depósito cancelado com sucesso',
          },
        },
        searchBar: 'Busque pelo nome ou CPF da pessoa',
      },
      failedToFetchMessage: 'Erro ao buscar informações de pedidos realizados',
    },
    refund: {
      title: 'Estorno de benefício',
      breadcrumbLabel: {
        order: 'Pedidos',
        orderDetails: 'Detalhes do pedido #{{code}}',
        refund: 'Estorno de benefício',
      },
      personDepositDetails: {
        title: 'Dados sobre a pessoa e depósito',
        name: 'Nome completo',
        cpf: 'CPF',
        deposit: 'Depósito',
        amount: 'Valor depositado em R$',
        refundAmount: 'Valor estornado para a empresa',
      },
      steps: {
        usageRules: {
          title: 'Regras de uso',
          subTitle:
            'Permite que você realize o estorno dos depósitos de benefícios disponibilizados as equipes',
          content: {
            title: 'Regras de uso',
            description:
              'Confira as informações necessárias para o processo de estorno',
            confirmedCode: 'Código confirmado!',
            permission: {
              title: 'Permissão de uso',
              text: 'O administrador precisa ter permissão para realizar pedidos por Flash Cash',
            },
            remainingBalance: {
              title: 'Saldo remanescente',
              text: 'O estorno não garante a devolução integral dos valores depositados pela empresa',
            },
            permittedTime: {
              title: 'Horário permitido',
              text: 'Estornos só podem ser realizados em dias úteis,  das {{initialHour}}h às {{finalHour}}h no fuso horário de Brasília',
            },
          },
          modalWarn: {
            title: 'Não é possível realizar o estorno de depósito',
            description:
              'Você está fora do dia ou horário permitido para realizar estornos pela plataforma. Em caso de dúvidas, contate nossa Central de Ajuda.',
          },
          continueButtonText: 'Continuar',
        },
        reviewDetailsAndConfirm: {
          title: 'Revise os detalhes e confirme',
          subTitle:
            'Cheque as informações antes de confirmar, essa ação não poderá ser desfeita após a realização do estorno',
          content: {
            title: 'Informações sobre o estorno',
            declaration:
              'Na forma de representante autorizado declaro, para todos os devidos fins de direito, que o pedido de estorno do valor indicado ocorreu em razão de erro operacional e/ou manual no momento da realização das cargas no cartão de benefício do colaborador da {{companyName}}',
          },
          continueButtonText: 'Confirmar estorno',
          modals: {
            insufficientBalance: {
              title: 'Depósito sem saldo disponível',
              description:
                'Não é possível realizar o estorno de depósito pois não há saldo disponível no depósito selecionado.',
            },
            errorProcessing: {
              title: 'Não é possível realizar o estorno de depósito',
              description:
                'Erro ao processar seu pedido de estorno. Em caso de dúvidas, contate nossa Central de Ajuda.',
            },
          },
        },
        reversalCompleted: {
          title: 'Estorno realizado',
          subTitle:
            'As informações sobre o depósito estornado foram compartilhadas com os outros administradores da empresa',
          content: {
            title: 'Estorno concluído!',
            description:
              'Veja os detalhes na área de movimentações em Flash Cash',
          },
          continueButtonText: 'Concluir',
          flashCashButtonText: 'Abrir Flash Cash',
        },
        tag: 'Não garantimos a devolução integral dos valores',
      },
      helpText: 'Se ainda tiver dúvidas, consulte nossa',
      helpCenterText: 'Central de Ajuda',
      cancelText: 'Cancelar',
    },
    newOrder: {
      title: 'Pedido por seleção',
      steps: {
        employeeSelection: 'Selecione as pessoas',
        paymentSetup: 'Defina o pagamento, disponibilização e descrição',
        reviewDetails: 'Revise os detalhes e confirme',
        orderCompleted: 'Pedido realizado',
      },
      buttons: {
        cancel: 'Cancelar',
        next: 'Continuar',
      },
      warnings: {
        selectEmployee:
          'É necessário selecionar pessoas para prosseguir com seu pedido.',
      },
      breadcrumb: {
        orders: 'Pedidos',
        makeOrder: 'Fazer pedido',
      },
      employeeSelection: {
        title: 'Selecione as pessoas que farão parte do seu pedido',
        subtitle:
          'Confira sempre o resumo do pedido para acompanhar as suas escolhas',
        search: {
          label: 'Buscar pelo nome da pessoa ou grupo',
        },
        editGroup: {
          text: 'Editar grupos',
          tooltip: 'Leva a página para modificar os grupos das pessoas',
        },
        tag: {
          selectedEmployee:
            '{{selectedEmployee}} de {{totalEmployee}} selecionados',
        },
        linkButton: {
          changeBenefitValues: 'Alterar valores de benefício',
        },
        bulkActions: {
          alterBenefit: 'Alterar valores de benefício',
        },
        pagination: {
          count: '{{total}} resultados',
        },
        table: {
          columns: {
            group: 'Grupo',
            benefits: 'Benefícios do grupo',
            totalAmount: 'Valor em R$',
          },
          noBenefits: 'Sem benefício',
          actionCell: {
            tooltip: 'Abrir atribuição de benefícios em nova aba',
          },
          groupNameCell: {
            person: 'pessoa',
            people: 'pessoas',
          },
        },
      },
      orderSummary: {
        title: 'Resumo do pedido',
        noSelection:
          'Quando começar a selecionar as pessoas da empresa poderá acompanhar uma prévia do seu pedido por aqui.',
        benefitsTitle: 'Benefícios Flash',
        orderTotals: {
          title: 'Total dos itens do pedido',
          tooltip:
            'O total dos itens do pedido são discriminados conforme a forma de utilização dos benefícios.',
          item: {
            benefitsCard: 'Benefícios no cartão',
          },
          totalPartial: {
            title: 'Total parcial',
          },
        },
      },
    },
  },
}

export default translations
