import styled from 'styled-components'

export const OrderTotalsHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${({ theme }) => theme.spacings.xs5};
`

export const OrderTotalsContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: ${({ theme }) => theme.spacings.xs2};

  gap: ${({ theme }) => theme.spacings.xs4};
`

export const TotalItemContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs4};

  > div {
    display: flex;
    align-items: center;

    gap: ${({ theme }) => theme.spacings.xs4};
  }
`
