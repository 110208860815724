import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { cpfMask } from 'src/utils/mask'
import { useTheme } from 'styled-components'

import { GroupNameWrapperStyled } from '../styles'
import { Employee, Group } from '../types'
import { isGroupRow } from '../utils/is-group-row'

type GroupNameCellProps = {
  groupOrEmployee: Group | Employee
}

export function GroupNameCell({ groupOrEmployee }: GroupNameCellProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <GroupNameWrapperStyled>
      <Typography
        variant="body3"
        weight={600}
        variantColor={theme.colors.neutral40}
      >
        {groupOrEmployee.name}
      </Typography>

      <div>
        {isGroupRow(groupOrEmployee) ? (
          <>
            <Icons name="IconUsers" size={16} />

            <Typography variant="body4">
              {groupOrEmployee.subRows.length}{' '}
              {groupOrEmployee.subRows.length > 1
                ? t('newOrder.employeeSelection.table.groupNameCell.people')
                : t('newOrder.employeeSelection.table.groupNameCell.person')}
            </Typography>
          </>
        ) : (
          <>
            <Icons name="IconId" size={16} />

            <Typography variant="body4">
              {cpfMask(groupOrEmployee.document)}
            </Typography>
          </>
        )}
      </div>
    </GroupNameWrapperStyled>
  )
}
