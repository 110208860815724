import { useTranslation } from 'react-i18next'
import { EmojiStack } from 'src/components/emoji-stack'
import { useTheme } from 'styled-components'

import { Employee, Group } from '../types'
import { isGroupRow } from '../utils/is-group-row'

type EmojisCellProps = {
  groupOrEmployee: Group | Employee
}

export function EmojisCell({ groupOrEmployee }: EmojisCellProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <EmojiStack
      emojis={groupOrEmployee.benefits}
      boxShadowColor={
        isGroupRow(groupOrEmployee) ? undefined : theme.colors.neutral95
      }
      noEmojisMessage={t('newOrder.employeeSelection.table.noBenefits')}
    />
  )
}
