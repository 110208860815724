export const DEBOUNCE_SELECTED_DELAY = 300
export const DEBOUNCE_SEARCH_PAGINATION_DELAY = 100

export const COLUMN_SIZES = {
  checkbox: 92,
  name: 292,
  benefits: 208,
  amount: 190,
  action: 100,
}
